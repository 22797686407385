import { Button, Step, StepContent, StepLabel, Stepper, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@linx-ui/shared/components/dialog';
import { GridLayoutContainer, GridLayoutItem } from '@linx-ui/shared/components/gridLayout';
import { StyledSkeleton } from '@linx-ui/shared/components/styles';
import { UserAvatar } from '@linx-ui/shared/components/userAvatar';
import { pharmacyIdKey } from '@linx-ui/shared/constants';
import { useFeaturePermissions, usePermissions } from '@linx-ui/shared/core';
import { useDeleteWrapper } from '@linx-ui/shared/hooks/useDeleteWrapper';
import { theme } from '@linx-ui/shared/theming';
import { getDateDisplayValueLTLS } from '@linx-ui/shared/utils';
import { useReactQueryList } from '@linx-ui/shared/hooks';
import { AlertMessage } from '@linx-ui/shared/components/alertMessage';
import { type Note } from '../types';
import { actionLabels } from './actionLabels';
import { deleteLabels } from './deleteLabels';
import { EditedTagStyled } from './NotesListing.styled';
import type { NotesListingProps } from './types';

const NotesListing: React.FC<NotesListingProps> = ({
  url,
  entityId,
  onEdit,
  onDelete,
  headerKeys,
  forceRender
}: NotesListingProps) => {
  const [deleteEntityId, setDeleteEntityId] = useState<string>();
  const canDelete = useFeaturePermissions()('note')('delete');
  const canUpdate = useFeaturePermissions()('note')('update');
  const { userName: loggedInUserName } = usePermissions();
  const sameUser = useCallback(
    (userName: string) => {
      return userName === loggedInUserName;
    },
    [loggedInUserName]
  );

  const onDeleteSuccess = () => {
    onDelete(deleteEntityId!);
    refreshData();
  };

  const {
    data: { items: notes },
    isLoading: loading,
    refetch: fetchData
  } = useReactQueryList<Note>({
    url: `${url}/${entityId}/notes`,
    headerKeys
  });

  useEffect(() => {
    refreshData();
  }, [forceRender]);

  const refreshData = () => {
    void fetchData();
  };

  const { deleteEntity, isDeleting } = useDeleteWrapper({
    onDelete: onDeleteSuccess,
    useOptimisticUpdate: false,
    url: `${url}/${entityId}/notes/${deleteEntityId}`,
    successToastMsg: deleteLabels.deleteSuccess,
    headerKeys: [pharmacyIdKey]
  });

  const edited = (createdAt: number | string, updatedAt: number | string) => {
    return createdAt !== updatedAt ? ' (edited)' : '';
  };

  return (
    <GridLayoutContainer style={{ height: '100%', overflow: 'auto' }}>
      <GridLayoutItem xs={12}>
        {!(loading || isDeleting) ? (
          notes.length ? (
            <Stepper orientation="vertical">
              {notes.map((note: Note) => {
                return (
                  <Step active={true} key={note.id}>
                    <StepLabel
                      StepIconProps={{
                        icon: <UserAvatar userName={note.recentHistory.createdByUser} />
                      }}
                    >
                      <GridLayoutContainer alignItems="center">
                        <GridLayoutItem item xs={4}>
                          <Typography variant="caption" color={theme.palette.text.secondary}>
                            {getDateDisplayValueLTLS(note.recentHistory.createdTime)}
                            <Tooltip
                              placement="bottom-start"
                              arrow
                              title={getDateDisplayValueLTLS(note.recentHistory.lastUpdatedTime)}
                            >
                              <EditedTagStyled>
                                {edited(note.recentHistory.createdTime, note.recentHistory.lastUpdatedTime)}
                              </EditedTagStyled>
                            </Tooltip>
                          </Typography>
                        </GridLayoutItem>
                        <GridLayoutItem
                          item
                          xs={8}
                          data-testid="btn-edit-note"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Button
                            color="secondary"
                            disabled={!canUpdate || !sameUser(note.recentHistory.createdByUser)}
                            onClick={() => {
                              onEdit(note.text, note.id);
                            }}
                          >
                            {actionLabels.edit}
                          </Button>

                          <Dialog
                            title={deleteLabels.deleteDialogTitle}
                            confirmBtnText={deleteLabels.deleteButton}
                            identifier={note.id}
                            confirmBtnColor="error"
                            onConfirm={(id?: string) => {
                              setDeleteEntityId(id);
                              deleteEntity({});
                            }}
                            TriggerComponent={(props: { onClick: () => void }) => (
                              <Button
                                disabled={!canDelete || !sameUser(note.recentHistory.createdByUser)}
                                color="secondary"
                                onClick={props.onClick}
                                data-testid="btn-delete-note"
                              >
                                {actionLabels.delete}
                              </Button>
                            )}
                          >
                            <>{deleteLabels.deleteConfirmation}?</>
                          </Dialog>
                        </GridLayoutItem>
                      </GridLayoutContainer>
                    </StepLabel>
                    <StepContent sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>{note.text}</StepContent>
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <AlertMessage alertText="No notes found" />
          )
        ) : (
          <>
            <StyledSkeleton />
            <StyledSkeleton />
          </>
        )}
      </GridLayoutItem>
    </GridLayoutContainer>
  );
};

export { NotesListing };
